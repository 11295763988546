export default {
  blogerName: 'ZOZIKS',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/ed_mundo7',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@EdMundo_ludi?si=-QExjjwgSzJYtePF',
    },
    {
      name: 'vk',
      url: 'http://vk.com/edosbonus',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://cutt.ly/hwQLSFyh',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://cutt.ly/OwuqK9hJ',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://cutt.ly/EwTHYLW6',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://cutt.ly/DNsocj3',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://cutt.ly/V8fJWl1',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://cutt.ly/NNEiuJM',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://cutt.ly/4wucdYKZ',
      gameTitle: 'Doors Of Fresh (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>ZOZIKS</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'ZOZIKS',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
